<template>
  <div>
    404
  </div>
</template>

<script>
export default {
  name: '404',
  data() {
    return {
      userName: '',
    }
  },
}
</script>

<style lang="less" scoped>
</style>